
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
.max-tablewrap-width {
  max-width: 250px;
  margin:0px;
}
.max-table-width {
  max-width: 150px;
}

.outer-triangle {
    position:absolute;
    margin-top:-8px;
    margin-left:-23px;
    width: 0; 
    height: 0; 
    border-right: 35px solid transparent;
    
    border-top: 0px;
}




.keeptogether {page-break-inside:avoid;}
.page
  {
    size: 210mm 297mm;
    /*margin: 25mm;*/
    /* overflow: hidden; */
    page-break-after: always;
    page-break-inside: avoid;
    margin-top:300px;
  }
 .arrow-up {
 	padding:-5px;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
} 
.base:before {
    border-bottom: 35px solid red;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -35px;
    width: 0;
}
.arrow-right {
    width: 0px;
    height: 0px;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid green;
}
.arrow-left {
    width: 0px;
    height: 0px;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid green; 
}
.navbar{
    border:0px;
}
.pagination > li > a { cursor: pointer; cursor: hand; }
.pagination > .active > a
{
    
    background-color: #7ec82f;
    border-color: #2b9d3c;
}
.pagination > .active > a:hover
{
    
    background-color: #2b9d3c;
    border-color: #7ec82f;
}
.btn-default, .btn-default, .btn-group.open .dropdown-toggle.btn-default, .btn-default:focus, {
    color:white;
    background-color: #2b9d3c;
    border-color: #7ec82f;
}
.btn-default:hover, .btn-group.open .dropdown-toggle.btn-default {
    color:white;
    background-color: #7ec82f;
    border-color: #2b9d3c;
}
.row .no-float {
  display: table-cell;
  float: none;
  padding:15px;
}
input[type='radio'] {
    -webkit-appearance:none;
    width:13px;
    height:13px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    
}

input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
}
input[type='radio']:checked:before {
    background:green;
}
#content {
    padding-left: 50px;
    padding-bottom: 25px;
}

/* inline styles from home view */
.flag-wrapper{
float:right;
width:30px;
} 
.flag-wrapper > a > img{
width:24px;
height:16px;
}
.panel-body > center >.table{
    max-width: 50%;
}
.panel-body > center >.table > tbody{
    border-top:0;
}
/* overrule bootstrap ! */
.table > tbody + tbody{
     border-top:0;
}
.table > tbody > tr > td:first-child{
     padding-left:23px;
}
.label-left{
    text-align: right;
}
td > label{
width:13px;
height:13px;    
}
#tag-right{
    text-align: right;
    padding-top: 32px;
}
.next-page-btn{
    margin-left:20px;
}
.gray{
    background:#F8F8F8;
}
.gray2{
    background:#E7E7E7;
}
.align-right{
    text-align: right;
}
/* inline styles from index view */
/* inline styles from main view */
.navbar-brand > span > img {
    padding:5px;max-height:35px;
}
#logout-form{
    display:none;
}